import { helpers } from 'gmap-vue'
const { MapElementFactory } = helpers;

const homeMarker = {
  path: "M10.453 14.016l6.563-6.609-1.406-1.406-5.156 5.203-2.063-2.109-1.406 1.406zM12 2.016q2.906 0 4.945 2.039t2.039 4.945q0 1.453-0.727 3.328t-1.758 3.516-2.039 3.070-1.711 2.273l-0.75 0.797q-0.281-0.328-0.75-0.867t-1.688-2.156-2.133-3.141-1.664-3.445-0.75-3.375q0-2.906 2.039-4.945t4.945-2.039z",
  fillColor: "blue",
  fillOpacity: 0.6,
  strokeWeight: 0,
  rotation: 0,
  scale: 2,
}

export default MapElementFactory({
  name: 'directionsRenderer',
  ctr: () => google.maps.DirectionsRenderer,
  //// The following is optional, but necessary if the constructor takes multiple arguments
  //// e.g. for GroundOverlay
  // ctrArgs: (options, otherProps) => [options],
  //events: ['directions_changed'],
  events: [],

  // Mapped Props will automatically set up
  //   this.$watch('propertyName', (v) => instance.setPropertyName(v))
  //
  // If you specify `twoWay`, then it also sets up:
  //   google.maps.event.addListener(instance, 'propertyName_changed', () => {
  //     this.$emit('propertyName_changed', instance.getPropertyName())
  //   })
  //
  // If you specify `noBind`, then neither will be set up. You should manually
  // create your watchers in `afterCreate()`.
  mappedProps: {
    panel: { },
    options: { type: Object },
/*  mappedProps: {
    routeIndex: { type: Number },
    directions: { type: Object },
    //// If you have a property that comes with a `_changed` event,
    //// you can specify `twoWay` to automatically bind the event, e.g. Map's `zoom`:
    // zoom: {type: Number, twoWay: true}
  },
  // Any other properties you want to bind. Note: Must be in Object notation
  props: {
    origin: {
      type: Object,
      required: true
    },
    destination: {
      type: Object,
      required: true
    }
*/
  },
  props: {
    origin: { type: [Object, Array] },
    destination: { type: [Object, Array] },
  },
  // Actions you want to perform before creating the object instance using the
  // provided constructor (for example, you can modify the `options` object).
  // If you return a promise, execution will suspend until the promise resolves
  beforeCreate (options) {
    options.preserveViewport = true
  },
  // Actions to perform after creating the object instance.
  afterCreate (directionsRendererInstance) {
    let directionsService = new google.maps.DirectionsService()

    this.$watch(
      () => [this.origin, this.destination, this.options],
      () => {
        let { origin, destination, options } = this
        if (!origin || !destination) return

        directionsService.route(
          {
            origin,
            destination,
            travelMode: options.travelMode
          },
          (response, status) => {
            this.$emit('update:directions', {})
            if (status !== "OK") return

            directionsRendererInstance.setDirections(response)
            this.$emit('update:directions', response)
          }
        );
      }
    );
  }
})
